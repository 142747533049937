import React from "react";
import TimetablePage from "../../components/timetable/TimetablePage";

export default function Dashboard() {
  return (
    <>
      <TimetablePage />
    </>
  );
}
